const seedData = [{
  "label": "Spending time with Dean and Evan",
  "value": 100,
  "icon": "far fa-house",
  "iconCode": "\ue00d"
}, {
  "label": "Beating on a keyboard",
  "value": 45,
  "icon": "fas fa-code",
  "iconCode": "\uf121"
}, {
  "label": "Making sure my team gets exactly what they need to be successful",
  "value": 70,
  "icon": "fas fa-shield-check",
  "iconCode": "\uf2f7"
}, {
  "label": "Empowering my team to have fun while building amazing technology",
  "value": 70,
  "icon": "far fa-laugh-squint",
  "iconCode": "\uf59b"
}, {
  "label": "Obsessing about Coffee",
  "value": 20,
  "icon": "fas fa-mug-hot",
  "iconCode": "\uf7b6"
}, {
  "label": "Big craft beer fan!",
  "value": 20,
  "icon": "fas fa-beer",
  "iconCode": "\uf0fc"
}, {
  "label": "Reading, Learning, Educating",
  "value": 25,
  "icon": "fas fa-book-reader",
  "iconCode": "\uf5da"
}];

// https://www.d3-graph-gallery.com/graph/donut_basic.html

class Chart {
  constructor() {
    console.log('Chart initiated.')
  }

  build(isDownloadable = false) {

    // Define size & radius of donut pie chart
    const width = isDownloadable ? 200 : 300;
    const height = isDownloadable ? 200 : 300;
    const radius = Math.min(width, height) / 2;
    
    // Define arc colours
    // const colour = d3.scaleOrdinal(d3.schemeCategory20);
    
    // Define arc ranges
    const arcText = d3.scaleOrdinal().range([0, width]);

    // Determine size of arcs
    const arc = d3.arc()
      .innerRadius(radius - 60)
      .outerRadius(radius);
    
    // Create the donut pie chart layout
    const pie = d3.pie()
      .padAngle(.02)
      .value(d => d["value"])
      .sort(null);
    
    // Append SVG attributes and append g to the SVG
    const svg = d3.select("#donut-chart")
      .attr("width", width)
      .attr("height", height)
      .append("g")
        .attr("transform", "translate(" + radius + "," + radius + ")");
    
    // Define inner circle
    svg.append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 100)
      .attr("fill", "#fff");
    
    // Calculate SVG paths and fill in the colours
    const g = svg.selectAll(".arc")
      .data(pie(seedData))
      .enter().append("g")
      .attr("class", "arc")
      .on("mouseover", (d, i) => {
        const parent = document.querySelector('#donut-text p')
        parent.textContent = seedData[i].label
        parent.className = 'no-print over'
      })
      .on("mouseout", (d, i) => {
        const parent = document.querySelector('#donut-text p')
        parent.textContent = 'Rollover and section above...'
        parent.className = 'no-print'
      })
      .on("click", (d, i) => {
        console.log('Value: ', seedData[i].label, seedData[i].value)
      });

      const style = getComputedStyle(document.querySelector('h2'))
      const colors = style.color.split(',', 3);
      colors[0] = parseInt(colors[0].replace('rgb(', ''));
      colors[1] = parseInt(colors[1]);
      colors[2] = parseInt(colors[2].replace(')', ''));
    
      // Append the path to each g
      g.append("path")
        .attr("d", arc)
        .attr("fill", (d, i) => {
          const opacity = (((1*10)-i)/10);
          return `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, ${opacity})`
        });

      
      // Append text labels to each arc
      g.append("text")
        .attr("class", "fa-icon")
        .attr("transform", d => {
          return "translate(" + arc.centroid(d) + ")";
        })
        .attr("dy", ".35em")
        .style("text-anchor", "middle")
        .attr("fill", "#fff")
        .text((d,i) => seedData[i].iconCode)
      
    g.selectAll(".arc text").call(this.wrap, arcText.range([0, width]));
    
    // Append text to the inner circle
    if (!isDownloadable) {
      svg.append("text")
        .attr("dy", ".3em")
        .style("text-anchor", "middle")
        .attr("class", "inner-circle")
        .attr("fill", "#36454f")
        // .text(d => `My Time`);
    }
    
  }

  // Wrap function to handle labels with longer text
  wrap (text, width) {
    text.each(function() {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
      // console.log("tspan: " + tspan);
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > 90) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
        }
      }
    });
  }

  hexToRgbA(hex) {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
  }

}

export default new Chart()