import Chart from './components/chart'

const getUrlVars = () => {
  var vars = {}
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => vars[key] = value)
  return vars
}

window.onload = () => {
  const vars = getUrlVars()
  if (vars.download) {
    document.querySelector('body').classList.add('downloadable')
  }
  Chart.build(vars.download || false);
}